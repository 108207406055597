import type {
    AppPermissions,
    TwoFactorProvision,
    UserFilter,
    UserOrder,
    UserType,
    UserTypeConnection,
} from '~/generated/types';
import { userFragment } from '~/graphql/fragments/user';

export type GetUserData = {
    user: UserType;
}

export type GetUserVariables = {
    id: string | number;
};

export const GET_USER_QUERY = gql`
    query GetUser($id: ID!) {
        user(pk: $id) {
            ...userFragment
        }
    }

    ${userFragment}
`;

export type GetUsersData = {
    users: UserTypeConnection;
    allUsers: {
        totalCount: number;
    };
}

export type GetUsersVariables = {
    first?: number;
    after?: string;
    order?: UserOrder;
    filter?: UserFilter;
};

export const GET_USERS_QUERY = gql`
    query GetUsers($first: Int, $after: String, $order: UserOrder, $filter: UserFilter) {
        users(first: $first, after: $after, order: $order, filters: $filter) {
            edges {
                node {
                    id
                    uuid
                    firstName
                    lastName
                    email
                    phone
                    skillset {
                        id
                        name
                        code
                        order
                    }
                    region {
                        id
                        name
                        code
                    }
                    dateJoined
                    lastLogin
                    isActive
                    isSuperuser
                    hasUsablePassword
                }
            }

            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }

            totalCount
        }
        
        allUsers: users {
            totalCount
        }
    }
`;

export const GET_SMS_USERS_QUERY = gql`
    query GetUsers($order: UserOrder, $filter: UserFilter) {
        users(order: $order, filters: $filter) {
            edges {
                node {
                    id
                    uuid
                    firstName
                    lastName
                    email
                    phone
                    skillset {
                        id
                        name
                    }
                    region {
                        id
                        name
                    }
                }
            }

            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }

            totalCount
        }

        allUsers: users(filters: { isActive: { exact: true } }) {
            totalCount
        }
    }
`;

export type GetPermissionsData = {
    permissions: AppPermissions[];
}

export type GetPermissionsVariables = {

};

export const GET_PERMISSIONS_QUERY = gql`
    query GetPermissions {
        permissions {
            app
            label
            groups {
                id
                label
                permissions {
                    permission
                    label
                }
            }
        }
    }
`;

export type Get2FProvisioningUriData = {
    twoFactorProvision: TwoFactorProvision;
}

export const GET_2F_PROVISIONING_URI_QUERY = gql`
    query Get2FProvisioningURI {
        twoFactorProvision {
            secret
            uri
            qrCode
        }
    }
`;
